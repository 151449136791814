<template>
  <div class="col-12 px-0">
    <div class="course" v-if="!courseDetail">
      <div class="row">
        <div class="col-12 col-lg-8">
          <h6 class="course__title mb-0">
            {{ course.course_name }}
          </h6>
        </div>

        <div class="
            col-12 col-lg-4
            d-flex
            justify-content-center justify-content-lg-end
            mt-2 mt-lg-0
          " style="gap: 10px">
          <div v-if="user_type === 'student'" style="gap: 10px" :class="[courseCollapse ? 'mr-4' : '', 'd-flex']"
            class="">
            <div v-if="course.is_favourite && course.is_favourite === 'Y'" class="remove-bookmark"
              @click.stop.prevent="removeCourseFromFavourite(course.course_id)">
              <b-button size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-success" class="">
                Unshortlist
              </b-button>
            </div>
            <div v-else class="add-bookmark" @click.stop.prevent="addCourseToFavourite(course.course_id)">
              <b-button size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-success" class="">
                Shortlist
              </b-button>
            </div>
            <div v-if="user_type === 'student'" class="text-right mt-lg-0">
              <b-button v-if="course.is_applied && course.is_applied === 'Y'" class="px-2" variant="success" size="sm"
                :disabled="true">APPLIED</b-button>
              <b-button class="px-2" variant="warning" size="sm" v-else @click.stop.prevent="(e) => {
                $emit('apply-course', course);
              }
                ">
                APPLY
              </b-button>
            </div>
          </div>
          <div v-if="
            ['counselor', 'agent', 'franchise', 'student_support'].includes(
              user_type
            )
          " :class="courseCollapse ? 'mr-3' : ''">
            <div>
              <b-button size="sm" @click.stop.prevent="() => {
                $emit('recommend-course', course);
              }
                " v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-success" class="">
                Recommend
              </b-button>
            </div>
          </div>
          <div v-if="['agent', 'franchise', 'student_support'].includes(user_type)"
            :class="courseCollapse ? 'mr-3' : ''">
            <div>
              <b-button size="sm" @click.stop.prevent="() => {
                $emit('apply-course-for-student', course);
              }
                " v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-success" class="">
                Apply
              </b-button>
            </div>
          </div>
        </div>
      </div>

      <div class="
          d-flex
          flex-column flex-md-row flex-wrap
          align-items-start
          justify-content-between
          course-details
          mt-2
        ">
        <div class="d-flex align-items-center course-point mb-2 mb-sm-1">
          <img src="@/assets/images/svg/cap.svg" alt="" class="mr-2" />
          <div>
            <p class="mb-0 text-muted small">Discipline</p>
            <p class="font-weight-bold">{{ course.course_category_name }}</p>
          </div>
        </div>

        <div class="d-flex align-items-center course-point mb-2 mb-sm-1">
          <img src="@/assets/images/svg/star-green.svg" alt="" class="mr-2" />
          <div>
            <p class="mb-0 text-muted small">Level</p>
            <p class="font-weight-bold">{{ course.course_level_name }}</p>
          </div>
        </div>

        <div class="d-flex align-items-center course-point mb-2 mb-sm-1">
          <img src="@/assets/images/svg/star-green.svg" alt="" class="mr-2" />
          <div>
            <p class="mb-0 text-muted small">Application Fee</p>
            <p class="font-weight-bold">
              <template v-if="course.application_fee">{{
                course.application_fee
              }}</template>
              <template v-else>None</template>
            </p>
          </div>
        </div>

        <div class="d-flex align-items-center course-point mb-2 mb-sm-1">
          <img src="@/assets/images/svg/money.svg" alt="" class="mr-2" />
          <div>
            <p class="mb-0 text-muted small">Tuition Fee (P.A.)</p>
            <p class="font-weight-bold" v-if="course.course_fee_pa">
              {{ course.currency }}
              {{ course.course_fee_pa.toLocaleString("en-IN") }}
            </p>
            <p class="font-weight-bold" v-else-if="course.course_fee">
              INR {{ course.course_fee.toLocaleString("en-IN") }}
            </p>
          </div>
        </div>

        <div class="d-flex align-items-center course-point">
          <img src="@/assets/images/svg/time.svg" alt="" class="mr-2" />
          <div>
            <p class="mb-0 text-muted small">Duration</p>
            <p class="font-weight-bold">
              {{ getCourseDuration(course) }}
            </p>
          </div>
        </div>
      </div>
      <!-- <div class="course-point">
          <img src="@/assets/images/svg/time.svg" alt="" />
          <div class="">
            <p>Intakes</p>
            <p>
              {{ formatIntake(course.intake) }}
            </p>
          </div>
        </div> -->
      <!-- <div v-if="
          ['agent', 'franchise', 'student_support'].includes(user_type) &&
          $store.getters['user/getUserDetails'].show_commission === 'Y' &&
          course.commission
        " class="course-point">
          <img src="@/assets/images/svg/money.svg" alt="" />
          <div class="">
            <p>
              Commission
            </p>
            <div class="flex-commission">
              <p>
                {{ getCommission(course) }}
              </p>
              <div v-b-tooltip.hover title="This is approximate value the final rate may vary" class="info-tag">
                <span>i</span>
              </div>
            </div>
          </div>
        </div> -->
      <!-- <div v-if="user_type === 'student'" class="text-right mt-1 mt-lg-0">
          <b-button
            v-if="course.is_applied && course.is_applied === 'Y'"
            class="px-2"
            variant="success"
            size="sm"
            :disabled="true"
            >APPLIED</b-button
          >
          <b-button
            class="px-2"
            variant="warning"
            size="sm"
            v-else
            @click.stop.prevent="
              (e) => {
                $emit('apply-course', course);
              }
            "
          >
            APPLY
          </b-button>
        </div> -->
      <!-- </div> -->
      <div class="
          d-flex
          flex-column flex-lg-row
          align-items-center
          justify-content-between
          mt-2
          p-1
          border-one-primary
        ">
        <div class="scholarship-box">
          <h4 class="font-weight-bold text-center text-black mb-1" style="padding-top: 20px">
            Scholarships
          </h4>
          <div v-if="
            (course.scholarships && course.scholarships.length > 1) ||
            course.scholarships.length === 1
          " class="scholarship-swiper">
            <swiper :navigation="course.scholarships && course.scholarships.length > 1 && true
              " class="mySwiper" @click.native.stop>
              <swiper-slide v-for="(data, index) in course.scholarships" :key="index + 'i'">
                <div class="text-center">
                  <div class="d-flex justify-content-center arrow-div" style="gap: 50px">
                    <p style="color: black">{{ data.sch_name }}</p>
                    <p>{{ data.sch_currency }} {{ data.sch_amount }}</p>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>

          <div v-else-if="course.scholarships.length === 1">
            <div :navigation="true" class="mySwiper">
              <div class="scholarship-box" v-for="(data, index) in course.scholarships" :key="index + 'i'">
                <div class="text-center">
                  <div class="d-flex justify-content-center" style="gap: 20px">
                    <p>{{ data.sch_name }}</p>
                    <p>{{ data.sch_currency }} {{ data.sch_amount }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div :navigation="true" class="mySwiper">
              <div class="scholarship-box empty-scholarship">
                <div class="scholarship-box empty-scholarship text-center">
                  <div class="d-flex justify-content-center mb-2" style="gap: 20px">
                    <h4>Check with our Counselor</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="intake-box" v-if="
          (course.course_intakes && course.course_intakes.length > 1) ||
          course.course_intakes.length === 1
        ">
          <div
            class="
              intake-tab-box
              d-flex
              justify-content-between
              align-items-start
            "
            style="gap: 20px"
          >
            <div class="d-flex flex-column" style="gap: 12px">
              <h4>Intakes</h4>
              <h4>Deadline</h4>
            </div>
            <div class="d-flex">
              <b-tabs
                v-model="activeTabIndex"
                nav-class="d-flex flex-nowrap course-intakes-tabs"
              >
                <b-tab
                  v-for="(intake, index) in course.course_intakes"
                  :key="index"
                  :title="intake.ci_month"
                  :disabled="isTabDisabled(intake.ci_deadline_date)"
                  class="intake-tab"
                  id="hhhshsh"
                >
                  <div class="d-flex" style="margin-top: 1.66rem">
                    <h5 class="mb-0" v-if="intake.ci_deadline_date">
                      {{ formatDeadline(intake.ci_deadline_date) }}
                    </h5>
                    <h5 class="mb-0" v-else>
                     no date
                    </h5>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
          <!-- <div class="d-flex">
            <h4>Intakes:</h4>
            <div>
              <div class="ml-2 mb-1 d-flex align-items-center" v-if="openIntakes.length">
                <span> </span>
                <button class="btn btn-success btn-sm mr-1">Open</button> <span>-</span>
                <span v-for="(intake, index) in openIntakes" :key="intake.cib_id">
                  <span class="ml-1">{{ intake.cib_month }}</span>
                  <span v-if="index < openIntakes.length - 1">, </span>
                </span>
              </div>
              <div class="ml-2 d-flex align-items-center" v-if="closedIntakes.length">
                <button class="btn btn-danger btn-sm mr-1">Closed</button> <span>-</span>
                <span v-for="(intake, index) in closedIntakes" :key="intake.cib_id">
                  <span class="ml-1">{{ intake.cib_month }}</span>
                  <span v-if="index < closedIntakes.length - 1">, </span>
                </span>
              </div>
            </div>
          </div> -->
          <!-- </div> -->
        </div>
        <div class="intake-box" v-else>
          <div class="
              intake-tab-box
              d-flex
              justify-content-between
              align-items-start
            " style="gap: 20px">
            <div class="d-flex flex-column" style="gap: 12px">
              <h4 class="mb-0">No Intake Information Available</h4>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="course-detail" v-if="courseDetail">
      <div v-if="course.application_fee">
        <div class="course-detail__sm-title">Application Fees</div>
        <div class="course-detail__text"></div>
      </div>
      <div v-if="course.course_benefits">
        <div class="course-detail__sm-title">Course Benefits</div>
        <div class="course-detail__text" v-html="course.course_benefits"></div>
      </div>
      <div v-if="course.general_eligibility">
        <div class="course-detail__sm-title">General Eligibility</div>
        <div class="course-detail__text">
          {{ course.general_eligibility }}
        </div>
      </div>
      <div v-if="course.languages_requirement">
        <div class="course-detail__sm-title">Language Requirement</div>
        <div class="course-detail__text">
          {{ course.languages_requirement }}
        </div>
      </div>
      <div v-if="course.intake">
        <div class="course-detail__sm-title">Intake</div>
        <div>
          <span>Intake Start: </span>
          <span>{{ course.intake }}</span>
        </div>
        <div v-if="course.intake_start">
          <span>Intake Start:</span>
          <span>{{ course.intake_start }}</span>
        </div>
        <div v-if="course.intake_end">
          <span>Intake End:</span>
          <span>{{ course.intake_end }}</span>
        </div>
        <div v-if="course.intake_deadline_1">
          <span>Intake Deadline 1:</span>
          <span>{{ course.intake_deadline_1 }}</span>
        </div>
        <div v-if="course.intake_deadline_2">
          <span>Intake Deadline 2:</span>
          <span>{{ course.intake_deadline_2 }}</span>
        </div>
        <div v-if="course.intake_deadline_3">
          <span>Intake Deadline 3:</span>
          <span>{{ course.intake_deadline_3 }}</span>
        </div>
        <div v-if="course.intake_deadline_4">
          <span>Intake Deadline 4:</span>
          <span>{{ course.intake_deadline_4 }}</span>
        </div>
        <div v-if="course.intake_deadline_5">
          <span>Intake Deadline 5:</span>
          <span>{{ course.intake_deadline_5 }}</span>
        </div>
        <div v-if="course.intake_deadline_6">
          <span>Intake Deadline 6:</span>
          <span>{{ course.intake_deadline_6 }}</span>
        </div>
      </div>
      <div>
        <div class="course-detail__sm-title">Additional Information</div>
        <div class="course-detail__text">
          <a :href="course.additional_information_link" target="_blank">{{
            course.additional_information_link
          }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BBadge,
  VBTooltip,
  VBPopover,
  BTabs,
  BTab,
} from "bootstrap-vue";
import StudentServices from "@/apiServices/StudentServices";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import helpers from "@/libs/helpers";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import { Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import moment from "moment";
SwiperCore.use([Navigation]);
export default {
  name: "Course",
  components: {
    BButton,
    BBadge,
    vSelect,
    VBPopover,
    Swiper,
    SwiperSlide,
    BTabs,
    BTab,
  },
  // setup() {
  //   return {
  //     modules: [Navigation],
  //   };
  // },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
    "b-popover": VBPopover,
  },
  props: {
    course: {
      type: Object,
      default: {},
    },
    courseDetail: {
      type: Boolean,
      default: false,
    },
    courseCollapse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTabIndex: 0, // Default to the nearest deadline tab
      nearestDeadlineIndex: 0,
      popupData: null,
      selectedStudents: [],
      studentsList: [],
      showTooltip: false,
      showIntakeMsg: true,
      currentMonth: new Date().getMonth(),
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 10,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  computed: {
    user_type() {
      if (store.getters["user/getUserDetails"].user_type) {
        return store.getters["user/getUserDetails"].user_type;
      } else {
        return null;
      }
    },
    commission_percent() {
      if (store.getters["user/getUserDetails"].commission_percent) {
        return store.getters["user/getUserDetails"].commission_percent;
      } else {
        return null;
      }
    },
    intakeMonths() {
      return formatIntake(this.course.intake)
        .split(", ")
        .map((month) => month.trim());
    },
    filteredIntakes() {
      const availableIntakes = this.course.intake.split('/');
      return this.course.course_intakes.filter(intake => availableIntakes.includes(intake.cib_month));
    },
    openIntakes() {
      return this.filteredIntakes.filter(intake => !this.isIntakeClosed(intake));
    },
    closedIntakes() {
      return this.filteredIntakes.filter(intake => this.isIntakeClosed(intake));
    },
    closestFutureIntakeIndex() {
      const now = new Date();
      const futureIntakes = this.course.course_intakes
        .map((intake, index) => ({ ...intake, index }))
        .filter((intake) => new Date(intake.ci_deadline_date) > now);

      if (futureIntakes.length === 0) return 0;

      // Find the closest future date
      const closestIntake = futureIntakes.reduce((prev, curr) =>
        new Date(curr.ci_deadline_date) < new Date(prev.ci_deadline_date)
          ? curr
          : prev
      );

      return closestIntake.index;
    },
  },
  methods: {
    formatDeadline(date) {
      return moment(date).format("DD MMM, YYYY");
    },
    isIntakeClosed(intake) {
      const currentMonth = moment().month() + 1; 
      const intakeMonth = moment(intake.cib_month, "MMMM").month() + 1;
      const bufferMonths = parseInt(intake.cib_buffer, 10);

      const monthsDifference = (intakeMonth - currentMonth + 12) % 12;

      const isWithinBuffer = monthsDifference <= bufferMonths;

      const isClosedIntake = this.course.closed_intakes.some(closedIntake => closedIntake.cim_month === intake.cib_month);

      return isWithinBuffer || isClosedIntake;
    },
    isTabDisabled(deadlineDate) {
      const today = new Date();
      const deadline = new Date(deadlineDate);
      return deadline < today;
    },
    setActiveNearestIntake() {
      const today = new Date();
      let nearestIndex = 0;
      let nearestDate = null;

      this.course.course_intakes.forEach((intake, index) => {
        const deadlineDate = new Date(intake.ci_deadline_date);
        if (
          deadlineDate >= today &&
          (!nearestDate || deadlineDate < nearestDate)
        ) {
          nearestDate = deadlineDate;
          nearestIndex = index;
        }
      });

      this.activeTabIndex = nearestIndex;
    },

    getBadgeVariant(month) {
      const currentMonth = new Date().getMonth();
      const monthIndex = new Date(Date.parse(month + " 1, 2023")).getMonth();
      if (monthIndex < currentMonth) {
        this.showIntakeMsg = false;
      }
      return monthIndex < currentMonth ? "danger" : "success";
    },
    getPopoverMessage(month) {
      const monthIndex = new Date(Date.parse(month + " 1, 2023")).getMonth();
      return `monthIndex < this.currentMonth
        ? Intakes closed for ${month}
        : Admissions open for ${month} intake`;
    },
    getCourseDuration(course) {
      try {
        if (course.course_year && course.course_month) {
          const y = parseInt(course.course_year);
          const m = parseInt(course.course_month);
          return `${y * 12 + m} Months`;
        } else if (course.course_year) {
          return `${course.course_year} Years`;
        } else if (course.course_month) {
          return `${course.course_month} Months`;
        }
      } catch (err) {
        console.error(err);
        return "";
      }
    },
    formatIntake: helpers.formatIntake,
    displayTooltip() {
      this.showTooltip = !this.showTooltip;
    },
    getCommission(course) {
      if (course.agent_commission_pa) {
        return `${course.currency} ${course.agent_commission_pa.toLocaleString(
          "en-IN"
        )}`;
      } else if (course.agent_commission) {
        return `INR ${course.agent_commission.toLocaleString("en-IN")}`;
      }
      return "";
    },

    addCourseToFavourite(course_id) {
      StudentServices.addCourseToFavourite({ course_id })
        .then((response) => {
          if (response.data.status) {
            this.$emit("reload-list");
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: "Course added to favourite",
                icon: "PlusCircleIcon",
                variant: "success",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: "Course not added to favourite",
                icon: "XIcon",
                variant: "failure",
              },
            });
          }
        })
        .catch((err) => {
          console.log("Error adding course to favourite ", err);
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Error adding course to favourite",
              icon: "XIcon",
              variant: "failure",
            },
          });
        });
    },
    removeCourseFromFavourite(course_id) {
      StudentServices.removeCourseFromFavourite({ course_id })
        .then((response) => {
          if (response.data.status) {
            this.$emit("reload-list");
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: "Course removed from favourite",
                icon: "MinusCircleIcon",
                variant: "success",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: "Course not removed from favourite",
                icon: "XIcon",
                variant: "failure",
              },
            });
          }
        })
        .catch((err) => {
          console.log("Error adding course to favourite ", err);
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Error removing course from favourite",
              icon: "XIcon",
              variant: "failure",
            },
          });
        });
    },
    resetSelectedStudents() {
      this.selectedStudents = [];
    },
    moment,

  },
  watch: {
    closestFutureIntakeIndex(newIndex) {
      this.activeTabIndex = newIndex;
    },
  },
  mounted() {
    this.setActiveNearestIntake();
  },
};
</script>

<style>
.empty-scholarship {
  width: 100% !important;
}

.course__title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.course__title:hover {
  white-space: normal;
  text-overflow: clip;
}

.course-point {
  display: flex;
  /* flex-direction: column; */
  align-items: flex-start;
  margin-right: 20px;
}

.course-detail>div {
  margin-bottom: 10px;
}

.course-detail__sm-title {
  font-weight: bold;
  text-decoration: underline;
  text-transform: uppercase;
}

.flex-commission {
  display: flex;
  align-items: center;
  gap: 10px;
}

.info-tag {
  color: var(--primary-1);
  font-weight: bold;
  border: 1px solid var(--primary-1);
  border-radius: 50%;
  padding: 0px 06px;
}

.tooltip-inner {
  color: #fff;
  background: #ff9900 !important;
}

.duration-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  /* Center items horizontally */
}

/* Style the individual duration items with fixed spacing */
.duration-item {
  /* margin: 10px 0; */
  text-align: left;
}

.intake-box {
  display: flex;
  gap: 5px;
  background-color: rgb(255 255 255 / 65%);
  border-radius: 15px;
  padding: 13px;
}

.swiper-slide {
  width: 100% !important;
}

[dir] .nav-tabs .nav-link {
  border: none;
  padding: 0.2rem 1rem;
  border-radius: 0;
  font-size: 14px;
}

.nav-tabs {
  margin-top: -6px;
}

.tab-content {
  margin-top: 11px;
}

.scholarship-box {
  /* width: 277px; */
  width: 50%;
  /* border: 2px solid var(--primary-1); */
  background-color: var(--primary-1-light-3);
  border-radius: 15px;
}

.border-one-primary {
  border: 2px solid var(--primary-1);
  border-radius: 15px;
}

.swiper-button-prev {
  background-image: url("data:image/svg+xml,%3Csvg width='30px' height='30px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpath d='M6 12H18M6 12L11 7M6 12L11 17' stroke='%23004cff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
  margin-top: -15px;
  background-repeat: no-repeat;
}

.swiper-button-next {
  background-image: url("data:image/svg+xml,%3Csvg width='30px' height='30px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpath d='M6 12H18M18 12L13 7M18 12L13 17' stroke='%23004cff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
  margin-top: -15px;
  background-repeat: no-repeat;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  content: "";
}

@media screen and (max-width: 768px) {
  .course-point img {
    width: 16px;
    /* Adjust the icon size as needed */
    height: 16px;
    /* Maintain aspect ratio */
  }

  .course-point p {
    font-size: 0.8rem;
    /* Adjust font size for small to medium devices */
  }

  .scholarship-box {
    width: 100%;
  }

  .arrow-div {
    height: 65px;
  }

  .intake-box {
    margin-top: 10px;
  }

  h4 {
    font-size: 1rem;
  }

  .swiper-button-prev {
    background-image: url("data:image/svg+xml,%3Csvg width='30px' height='30px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpath d='M6 12H18M6 12L11 7M6 12L11 17' stroke='%23004cff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    margin-top: 8px;
    background-repeat: no-repeat;
  }

  .swiper-button-next {
    background-image: url("data:image/svg+xml,%3Csvg width='30px' height='30px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpath d='M6 12H18M18 12L13 7M18 12L13 17' stroke='%23004cff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    margin-top: 8px;
    background-repeat: no-repeat;
  }
}

@media screen and (max-width: 850px) {
  .scholarship-box {
    width: 100%;
  }

  .intake-box {
    margin-top: 40px;
    width: 100%;
  }

  .intake-box .intake-tab-box {
    margin: auto auto;
    gap: 15px;
  }
}

@media screen and (max-width: 390px) {
  .arrow-div {
    height: 75px;
  }

  .swiper-button-prev {
    background-image: url("data:image/svg+xml,%3Csvg width='30px' height='30px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpath d='M6 12H18M6 12L11 7M6 12L11 17' stroke='%23004cff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    margin-top: 12px;
    background-repeat: no-repeat;
  }

  .swiper-button-next {
    background-image: url("data:image/svg+xml,%3Csvg width='30px' height='30px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpath d='M6 12H18M18 12L13 7M18 12L13 17' stroke='%23004cff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    margin-top: 12px;
    background-repeat: no-repeat;
  }

  h4 {
    font-size: 0.8rem;
    padding-top: 2px;
  }

  /* .intake-box {
    margin-top: 40px;
    width: 100%;
  } */
  .intake-box .intake-tab-box {
    /* margin: auto auto; */
    gap: 6px !important;
  }

  h5 {
    font-size: 0.8rem;
  }

  .nav-tabs .nav-link {
    font-size: 9px !important;
  }
}

.course-intakes-tabs {
  width: 200px;
  overflow-x: scroll;
}
</style>
